import { Link, Tag } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import { t } from "@/translations";
import React from "react";
import { CustomImageState as CustomImageStateEnum } from "./CustomImage.component";

const getOptionsByState = state => {
    switch (state) {
        case CustomImageStateEnum.ERROR:
            return {
                color: "error",
                title: t("custom-image-state.conversion.broken"),
            };
        case CustomImageStateEnum.BEING_CONVERTED:
            return {
                color: "processing",
                title: t("custom-image-state.conversion.being-converted"),
            };
        case CustomImageStateEnum.BEING_REBUILT:
            return {
                color: "processing",
                title: t("custom-image-state.conversion.being-rebuilt"),
            };
        case CustomImageStateEnum.READY:
        default:
            return {
                color: "success",
                title: t("custom-image-state.conversion.ok"),
            };
    }
};

const CustomImageState = ({ customImageState, onRebuild }) => {
    const currentState = getOptionsByState(customImageState);

    return (
        <>
            <Tag color={currentState.color}>{currentState.title}</Tag>
            <Link onClick={onRebuild}>
                {t("custom-image-state.action.rebuild")}
            </Link>
        </>
    );
};
CustomImageState.propTypes = {
    customImageState: PropTypes.oneOf(Object.values(CustomImageStateEnum))
        .isRequired,
    onRebuild: PropTypes.func.isRequired,
};

export default CustomImageState;

import {
    Button,
    Checkbox,
    Input,
    Option,
    Select,
    Table,
} from "@/components/DesignSystem";
import { createEmptyColumn } from "@/components/DesignSystem/Table/Table.component";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import { t } from "@/translations";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styles from "./WaterfallConfiguration.style.less";

const createColumns = (
    handleChange,
    onDataMartFieldSelect,
    dataMartFields,
    onSubLevelRemoveRow,
) => [
    createEmptyColumn({ width: 20 }),
    {
        label: t("packages.waterfall.sub-level.select.source"),
        name: "name",
        width: 195,
        render: (text, record) => (
            <div className={styles.inlineContainerSubLevel}>
                <Select
                    onSelect={value => onDataMartFieldSelect(record, value)}
                    value={record.name}
                    allowClear={false}
                    dropdownMatchSelectWidth={false}
                >
                    {dataMartFields.map(field => (
                        <Option
                            title={field.name}
                            key={field.name}
                            value={field.name}
                        >
                            {field.name}
                        </Option>
                    ))}
                </Select>
                {record.name && (
                    <Button
                        onClick={() => onSubLevelRemoveRow(record)}
                        icon={Trash}
                    />
                )}
            </div>
        ),
    },
    {
        label: t("packages.waterfall.sub-level.select.source"),
        name: "label",
        width: 200,
        render: (text, record) => (
            <StopOnKeyDownPropagation>
                <Input
                    onChange={e =>
                        handleChange(record.id, "label", e.target.value)
                    }
                    value={record.label}
                />
            </StopOnKeyDownPropagation>
        ),
    },
    createEmptyColumn({ width: 104 }),
    createEmptyColumn({ width: 104 }),
    {
        label: t("packages.waterfall.table.header.reverse"),
        name: "isSubtract",
        width: 104,
        render: (text, record) => (
            <Checkbox
                value={record.isSubtract}
                disabled={record.name === null}
                onChange={e => handleChange(record.id, "isSubtract", e.value)}
            />
        ),
    },
    {
        label: t("packages.waterfall.table.header.disabled"),
        name: "disabled",
        width: 90,
        render: (text, record) => (
            <Checkbox
                value={record.disabled}
                onChange={e => handleChange(record.id, "disabled", e.value)}
            />
        ),
    },
];

export const SubLevel = ({
    parentId,
    subFields,
    dataMartFields,
    onDataMartFieldSelect,
    onDrop: onDropProp,
    onSubLevelRemoveRow,
    handleCheckBoxes,
}) => {
    const onDrop = useMemo(() => onDropProp(parentId), [onDropProp, parentId]);

    const removeAlreadySelected = (subFields, dataMartFields = []) => {
        const visibleFieldsNames = subFields.map(field => field.name);

        return dataMartFields.filter(
            field => !visibleFieldsNames.includes(field.name),
        );
    };

    return (
        <Table
            rowDragDrop={{
                onDrop,
            }}
            rowHeight={50}
            className="pmTable--condensed pmTable--tallCells waterfallTable--subLevel"
            rowKey="id"
            columns={createColumns(
                handleCheckBoxes,
                onDataMartFieldSelect,
                removeAlreadySelected(subFields, dataMartFields),
                onSubLevelRemoveRow,
            )}
            dataSource={subFields}
            paging={false}
        />
    );
};

SubLevel.propTypes = {
    parentId: PropTypes.string.isRequired,
    subFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataMartFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDataMartFieldSelect: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onSubLevelRemoveRow: PropTypes.func.isRequired,
    handleCheckBoxes: PropTypes.func.isRequired,
};

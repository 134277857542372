import { UnityLayout } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const Sidebar = ({ children }) => {
    return (
        <UnityLayout.Sider width={240} placement="left" responsive expandable>
            <UnityLayout>
                <UnityLayout.Content>{children}</UnityLayout.Content>
            </UnityLayout>
        </UnityLayout.Sider>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    filter: PropTypes.node,
    children: PropTypes.node.isRequired,
};

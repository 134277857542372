import { Dropdown, Menu } from "@/components/DesignSystem";
import { Checkbox } from "@/components/DesignSystem/Checkbox";
import { CaretDownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const CheckboxDropdown = ({
    checked,
    onChange,
    checkItemText,
    uncheckItemText,
}) => {
    const [visible, setVisible] = useState(false);

    return (
        <Dropdown
            trigger={["click"]}
            visible={visible}
            onVisibleChange={setVisible}
            overlay={
                <Menu>
                    <Menu.Item key="0" onClick={() => onChange(false)}>
                        {uncheckItemText}
                    </Menu.Item>
                    <Menu.Item key="1" onClick={() => onChange(true)}>
                        {checkItemText}
                    </Menu.Item>
                </Menu>
            }
        >
            <div
                onClick={() => setVisible(true)}
                style={{
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                <Checkbox value={checked} />
                &nbsp;
                <CaretDownOutlined />
            </div>
        </Dropdown>
    );
};

CheckboxDropdown.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checkItemText: PropTypes.node,
    uncheckItemText: PropTypes.node,
};

import { TableCellField } from "@/components/DataUploads/Wizard/NewTable/components/ConfigureStep";
import { useFooterButtons } from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
import {
    Alert,
    Button,
    Forms,
    Gap,
    Icon,
    Table,
    Text,
} from "@/components/DesignSystem";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { useLoadableTableProps } from "@/components/Integrations/ApplicationProperties/loadables";
import {
    usePropertiesUsageQuery,
    useTransportMutation,
} from "@/components/Integrations/components/TransportModal/loadables";
import { PreventClick } from "@/components/PartitionRequest/PartitionModules.component";
import {
    hasError,
    hasValue,
    isLoading,
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { t } from "@/translations";
import {
    getErrorMessage,
    getErrorMessageFromError,
} from "@/utils/state/error.utils";
import {
    useFormTouched,
    useFormValidationLoadable,
} from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { FIELD_TYPES } from "@pricefx/unity-components/dist/es/constants";
import {
    entries,
    filter,
    forEach,
    fromPairs,
    get,
    map,
    pipe,
    toPairs,
    transform,
    values,
} from "lodash/fp";
import React, { useCallback, useEffect, useMemo } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const { useForm, pmValidators } = Forms;

// : char is some kind of separator in the UC Forms, so we need to escape it
const escapeValueForFormId = name => `${name}_input`.replaceAll(":", "");

const toRoutesStr = routes => routes?.join?.(", ") || "";
const createPropertiesColumns = ({ selectedRowKeys }) => [
    {
        name: "property",
        label: t("instances.transport.to.modal.select-objects.property"),
    },
    {
        name: "value",
        label: t("instances.transport.to.modal.value"),
        render: (value, { _id, encrypted }) => {
            return encrypted ? (
                <PreventClick>
                    <TableCellField
                        Component={Forms.Fields.Input}
                        size="small"
                        key={_id}
                        name={_id}
                        placeholder="Value must be defined"
                        disabled={!selectedRowKeys.includes(_id)}
                        validator={
                            selectedRowKeys.includes(_id)
                                ? pmValidators.isRequired
                                : null
                        }

                        // initialValue={selectedKeys.includes(record.key)}
                    />
                </PreventClick>
            ) : (
                value
            );
        },
    },
    {
        name: "routes",
        label: t("instances.transport.to.modal.select-objects.routes"),
        render: (_, { routes }) => {
            const routesStr = toRoutesStr(routes);

            return (
                <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "100%",
                    }}
                >
                    <div
                        style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {routesStr}
                    </div>
                    <div>{routes?.length || ""}</div>
                </div>
            );
        },
    },
    {
        type: FIELD_TYPES.TEXT,
        name: "copy",
        isIconOnly: true,
        render: (_, { routes }) => {
            const text = toRoutesStr(routes);
            if (!text) return null;

            return (
                <PreventClick>
                    <CopyToClipboard text={text}>
                        <Button
                            type="link"
                            size="small"
                            label={<Icon type="copy" />}
                        />
                    </CopyToClipboard>
                </PreventClick>
            );
        },
    },
];

export const SelectPropertiesStep = ({
    stepProps: {
        instanceId,
        instanceName,
        onFinish,
        onCancel,
        setAlert,
        targetEnvironment,
        transportObjects,
    },
}) => {
    const propertiesQuery = usePropertiesUsageQuery({
        instanceId,
        targetEnvironment,
        payload: transportObjects.paths,
    });
    const error =
        hasError(propertiesQuery) &&
        getErrorMessageFromError(propertiesQuery.loadable.contents);
    useEffect(() => {
        if (error)
            setAlert({
                message: error,
                showIcon: true,
                type: "error",
                banner: true,
            });
        else setAlert(null);
    }, [error, setAlert]);

    const propertiesDSLoadable = useMapLoadableMemoized(
        propertiesQuery.loadable,
        useCallback(
            data =>
                pipe(
                    values,
                    transform(
                        (result, value) =>
                            pipe(
                                toPairs,
                                forEach(([property, propertyUsage]) => {
                                    const transformedObj = {
                                        _id: escapeValueForFormId(property),
                                        key: escapeValueForFormId(property),
                                        property: property,
                                        value: propertyUsage.value,
                                        encrypted: propertyUsage.encrypted,
                                        routes: propertyUsage.relatedRoutes,
                                        alerts: propertyUsage.encrypted
                                            ? {
                                                  property: t(
                                                      "instances.transport.encrypted-property-tooltip",
                                                  ),
                                                  property_ALERT_TYPE: "YELLOW",
                                              }
                                            : undefined,
                                    };
                                    result.push(transformedObj);
                                }),
                            )(value),
                        [],
                    ),
                )(data),
            [],
        ),
    );
    const tableProps = useLoadableTableProps(propertiesDSLoadable);
    const propertiesMaybe = propertiesQuery.loadable.valueMaybe();
    const propertiesDataSourceMaybe = propertiesDSLoadable.valueMaybe();

    const transportMutation = useTransportMutation({
        detailed: true,
        instanceId,
        targetEnvironment,
        afterSuccess: () => {
            onFinish();
        },
        afterError: error =>
            setAlert({
                message: getErrorMessage(error.response.data),
                showIcon: true,
                type: "error",
                banner: true,
            }),
    });
    const { mutate } = transportMutation; // TODO: without this line - infinite render loop
    const rowSelection = hooks.useRowSelection({
        rowClickPersistOthers: true,
        disableOnRowClick: true,
        onSelectionChange: async selectedRowKeys => {
            const { values: formValues } = await getBag();

            setValues(
                Object.entries(formValues).reduce(
                    (result, [property, value]) => ({
                        ...result,
                        [property]: selectedRowKeys.includes(property)
                            ? value
                            : undefined,
                    }),
                    {},
                ),
            );
        },
    });
    const { selectedRowKeys, changeRowKeys } = rowSelection;

    const onSubmit = useCallback(
        ({ values }) => {
            if (!propertiesMaybe)
                throw new Error("propertiesMaybe is not defined");
            pipe(
                entries,
                map(([path, obj]) => [
                    path,
                    pipe(
                        entries,
                        filter(([path]) =>
                            selectedRowKeys.includes(
                                escapeValueForFormId(path),
                            ),
                        ),
                        map(([path, obj]) => [
                            path,
                            {
                                needsEncryption: obj.encrypted,
                                value:
                                    values[escapeValueForFormId(path)] ??
                                    obj.value,
                            },
                        ]),
                        fromPairs,
                    )(obj),
                ]),
                fromPairs,
                mutate,
            )(propertiesMaybe);
        },
        [propertiesMaybe, mutate, selectedRowKeys],
    );
    const {
        setAllToTouched,
        revalidate,
        submit,
        formId,
        Form,
        handleSubmit,
        getBag,
        setValues,
        setBanner,
    } = useForm({
        onSubmit,
    });

    useEffect(() => {
        pipe(
            filter(get("routes.length")),
            map(get("_id")),
            changeRowKeys,
        )(propertiesDataSourceMaybe || []);
    }, [changeRowKeys, propertiesDataSourceMaybe]);

    const submitDisabled =
        !hasValue(propertiesQuery) || isLoading(transportMutation);
    // !rowSelection.selectedRowKeys?.length;

    const touched = useFormTouched(formId);
    const validation = useFormValidationLoadable(formId);
    const formLoadables = useComposeLoadablesMemoized([touched, validation]);
    useLoadableHasValueChangedEffect(
        formLoadables,
        ([touched, validation]) => {
            if (
                touched.touched &&
                !validation.isValid &&
                touched.touchedFieldIds.has(validation.errors?.[0]?.name)
            ) {
                const foundItem = tableProps.dataSource.find(
                    ({ _id }) => _id === validation.errors[0].name,
                );
                setBanner({
                    message: `Property ${foundItem.property} must contain a value.`,
                });
            } else {
                setBanner(null);
            }
        },
        [],
    );
    useFooterButtons(
        useMemo(
            () => [
                {
                    label: t("instances.transport.to.submit"),
                    type: "primary",
                    // instead of formId, we need to prioritize our custom validation message in the banner, instead of common UC validation error
                    onClick: () => {
                        setAllToTouched();
                        revalidate();
                        if (validation.valueMaybe()?.isValid) {
                            submit();
                        }
                    },
                    disabled: submitDisabled,
                },
                {
                    label: t("general.cancel"),
                    type: "text",
                    onClick: onCancel,
                },
            ],
            [
                onCancel,
                revalidate,
                setAllToTouched,
                submit,
                submitDisabled,
                validation,
            ],
        ),
    );

    return (
        <Form formId={formId} onSubmit={handleSubmit}>
            <Text>
                {t("instances.transport.to.modal.select-objects", {
                    instanceName,
                })}
            </Text>
            <Gap />
            <Table
                className="pmTable--withInputs"
                rowKey="_id"
                columns={createPropertiesColumns({ selectedRowKeys })}
                fixedHeight={325}
                rowSelection={rowSelection}
                pagination={false}
                padding={false}
                hasColumnResizing
                {...tableProps}
            />
            <Gap />
            <Alert
                showIcon
                type="info"
                message=" "
                description={t(
                    "instances.transport.to.modal.select-objects.slow-warning",
                )}
            />
        </Form>
    );
};

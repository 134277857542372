import ErrorAlert from "@/components/Alerts/ErrorAlert";
import {
    Button,
    ButtonGroup,
    Checkbox,
    Form,
    Gap,
    H5,
    Input,
    Option,
    Select,
    Table,
    Text,
    Tooltip,
} from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import { T, t } from "@/translations";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import { produce } from "immer";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import uuid from "uuid/v4";
import { StepUiHeaders } from "../TableComponents/Skip";
import { SubLevel } from "./SubLevel.component";
import styles from "./WaterfallConfiguration.style.less";
import { useWaterfallValidation } from "./WaterfallConfiguration.validator";

const initSubLevel = () => ({
    id: uuid(),
    name: null,
    label: null,
    disabled: false,
    isSubtract: false,
});

const CONFIG_NAME = "waterfall-configuration";
const createColumns = (
    handleChange,
    onDataMartFieldSelect,
    dataMartFields,
    handleRemoveRow,
    validationColumns,
) => [
    {
        label: t("packages.waterfall.select.source"),
        name: "Source",
        width: 200,
        render: (_, record) => (
            <div className={styles.inlineContainer}>
                <Select
                    className={styles.waterfallInput}
                    onChange={value => onDataMartFieldSelect(record, value)}
                    value={record.name}
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    {...validationColumns["name"](record)}
                >
                    {dataMartFields.map(field => (
                        <Option
                            title={field.name}
                            key={field.name}
                            value={field.name}
                        >
                            {field.name}
                        </Option>
                    ))}
                </Select>
                <Button onClick={() => handleRemoveRow(record)} icon={Trash} />
            </div>
        ),
    },
    {
        label: t("packages.waterfall.table.header.label"),
        name: "label",
        width: 200,
        render: (_, record) => (
            <StopOnKeyDownPropagation>
                <Input
                    onChange={e =>
                        handleChange(record.id, "label", e.target.value)
                    }
                    value={record.label}
                />
            </StopOnKeyDownPropagation>
        ),
    },
    {
        label: t("packages.waterfall.table.header.sum"),
        name: "isSum",
        width: 104,
        render: (_, record, index) => (
            <Checkbox
                value={record.isSum}
                onChange={e => handleChange(record.id, "isSum", e.value)}
                {...validationColumns["isSum"](record, index)}
            />
        ),
    },
    {
        label: t("packages.waterfall.table.header.percent-base"),
        name: "isPercentBase",
        width: 104,
        render: (_, record) => (
            <Checkbox
                value={record.isPercentBase}
                onChange={e =>
                    handleChange(record.id, "isPercentBase", e.value)
                }
            />
        ),
    },
    {
        label: t("packages.waterfall.table.header.reverse"),
        name: "isSubtract",
        width: 104,
        render: (_, record) => (
            <Checkbox
                value={record.isSubtract}
                onChange={e => handleChange(record.id, "isSubtract", e.value)}
                {...validationColumns["isSubtract"](record)}
            />
        ),
    },
    {
        label: t("packages.waterfall.table.header.disabled"),
        name: "disabled",
        width: 104,
        render: (_, record) => (
            <Checkbox
                value={record.disabled}
                onChange={e => handleChange(record.id, "disabled", e.value)}
            />
        ),
    },
];

const mapDatamartSubLevel = subLevels => {
    if (!subLevels) return [initSubLevel()];
    return [
        ...subLevels.map(subLevel => ({ id: uuid(), ...subLevel })),
        initSubLevel(),
    ];
};

const mapDatamart = dataMart => {
    const fields = dataMart.fields.map(field => ({
        ...field,
        id: uuid(),
        name: field.name || null,
        isAllowedToCreateSubLevel: !field?.name,
        subLevel: mapDatamartSubLevel(dataMart.fields.subLevel),
        isMandatoryField: false,
    }));

    const preselectedFields = dataMart.mandatoryFields.map(field => ({
        ...field,
        id: uuid(),
        name: field.name || null,
        isAllowedToCreateSubLevel: !field?.name,
        subLevel: mapDatamartSubLevel(field.subLevel),
        isMandatoryField: true,
    }));

    return {
        ...dataMart,
        fields,
        preselectedFields,
    };
};

const removeAlreadySelected = (fields, dataMartFields = []) => {
    const visibleFieldsNames = fields.map(field => field.name);

    return dataMartFields.filter(
        field => !visibleFieldsNames.includes(field.name),
    );
};

const buildUpConfiguration = (dataMartName, fields) => {
    const configFields = fields.map(field => {
        let ommitedField = omit(field, [
            "id",
            "isAllowedToCreateSubLevel",
            "isMandatoryField",
        ]);
        ommitedField.subLevel = field.subLevel
            .map(subField => {
                return omit(subField, ["id"]);
            })
            .filter(subField => subField.name !== null);

        if (!field?.subLevel[0]?.name || !field.isAllowedToCreateSubLevel) {
            ommitedField = omit(ommitedField, ["subLevel"]);
        }
        return ommitedField;
    });

    return {
        [CONFIG_NAME]: { dataMart: dataMartName, fields: configFields },
    };
};

export const WaterfallConfiguration = ({ step, onNext, onBack, onCancel }) => {
    const { dataMarts } = step;
    const [fields, setFields] = useState(null);
    const [dataMart, setDataMart] = useState(null);

    const validation = useWaterfallValidation(fields);

    const handleOnNext = () => {
        onNext(buildUpConfiguration(dataMart.name, fields));
    };

    const handleFieldChange = (fieldId, columnName, value) => {
        switch (columnName) {
            case "isSum":
                setFields(fields =>
                    produce(fields, draft => {
                        draft
                            .filter(field => field.id === fieldId)
                            .forEach(field => {
                                field[columnName] = value;
                                field.isAllowedToCreateSubLevel = !value;
                            });
                    }),
                );
                break;
            case "isPercentBase":
                setFields(fields =>
                    produce(fields, draft => {
                        draft.forEach(field => {
                            field[columnName] =
                                field.id === fieldId ? value : false;
                        });
                    }),
                );
                break;

            default:
                setFields(fields =>
                    produce(fields, draft => {
                        draft
                            .filter(field => field.id === fieldId)
                            .forEach(field => (field[columnName] = value));
                    }),
                );
                break;
        }
    };

    const handleSubLevelChange = (subLevelId, columnName, value) => {
        setFields(fields =>
            produce(fields, draft => {
                const fieldIndex = findFieldIndexBySublevel(fields, subLevelId);
                const selectedSublevel = draft[fieldIndex].subLevel.find(
                    field => field.id === subLevelId,
                );

                selectedSublevel[columnName] = value;
            }),
        );
    };

    const addRow = () => {
        setFields(fields =>
            produce(fields, draft => {
                const subLevel = [initSubLevel()];
                draft.push({
                    id: uuid(),
                    name: null,
                    isSum: false,
                    isPercentBase: false,
                    isSubtract: false,
                    disabled: false,
                    subLevel,
                    isAllowedToCreateSubLevel: true,
                });
            }),
        );
    };

    const handleRemoveRow = record =>
        setFields(fields => fields.filter(field => field.id !== record.id));

    const handleSubLevelRemoveRow = subLevel => {
        setFields(fields =>
            produce(fields, draft => {
                const fieldIndex = findFieldIndexBySublevel(
                    fields,
                    subLevel.id,
                );
                const isLastRow = draft[fieldIndex].subLevel.length === 1;
                draft[fieldIndex].subLevel = draft[fieldIndex].subLevel.filter(
                    field => field.id !== subLevel.id,
                );
                if (isLastRow) {
                    draft[fieldIndex].subLevel.concat(initSubLevel());
                }
            }),
        );
    };
    const onDataMartSelect = name => {
        const selectedDataMart = mapDatamart(
            dataMarts.find(mart => mart.name === name),
        );
        setDataMart(selectedDataMart);
        setFields(selectedDataMart.preselectedFields);
    };

    const findFieldInFieldsDefinition = name => {
        return dataMart.fields.find(field => field.name === name);
    };

    const onDataMartFieldSelect = (record, name) => {
        setFields(fields =>
            produce(fields, draft => {
                const fieldIndex = findFieldIndex(fields, record.id);
                draft[fieldIndex].name = name ? name : null;

                draft[fieldIndex].label =
                    findFieldInFieldsDefinition(name)?.label;

                draft[fieldIndex].isAllowedToCreateSubLevel = !name;
            }),
        );
    };

    const findFieldIndex = (fields, fieldId) =>
        fields.findIndex(field => field.id === fieldId);

    const findFieldIndexBySublevel = (fields, subLevelId) => {
        return fields.reduce((acc, field, index) => {
            if (
                field.subLevel.findIndex(
                    subLevel => subLevel.id === subLevelId,
                ) >= 0
            ) {
                return (acc = index);
            }
            return acc;
        }, -1);
    };

    const onDataMartFieldSubLevelSelect = (subLevel, name) => {
        setFields(fields =>
            produce(fields, draft => {
                const fieldIndex = findFieldIndexBySublevel(
                    fields,
                    subLevel.id,
                );
                const selectedSublevel = draft[fieldIndex].subLevel.find(
                    field => field.id === subLevel.id,
                );

                selectedSublevel.name = name;

                const foundField = findFieldInFieldsDefinition(name);

                selectedSublevel.label = foundField?.label;

                const isLastFieldEmpty =
                    draft[fieldIndex].subLevel[
                        draft[fieldIndex].subLevel.length - 1
                    ].name === null;

                if (!isLastFieldEmpty) {
                    draft[fieldIndex].subLevel.push(initSubLevel());
                }
            }),
        );
    };

    const onSubLevelClear = record => {
        setFields(fields =>
            produce(fields, draft => {
                const fieldIndex = findFieldIndex(fields, record.id);
                draft.splice(fieldIndex, 1);
            }),
        );
    };

    const onSubFieldDrop = useCallback(
        recordId =>
            ({ oldIndex: from, newIndex: to }) => {
                setFields(fields =>
                    produce(fields, draft => {
                        const fieldIndex = findFieldIndex(fields, recordId);
                        const subFieldsCount =
                            draft[fieldIndex].subLevel.length;

                        if (
                            from === subFieldsCount - 1 ||
                            to === subFieldsCount - 1
                        ) {
                            return;
                        }

                        draft[fieldIndex].subLevel.splice(
                            to,
                            0,
                            draft[fieldIndex].subLevel.splice(from, 1)[0],
                        );
                    }),
                );
            },
        [],
    );

    const onDrop = useCallback(({ oldIndex: from, newIndex: to }) => {
        setFields(fields =>
            produce(fields, draft => {
                // first row cannot be the sumfield
                if (draft[from].isSum && to === 0) {
                    return;
                }

                draft.splice(to, 0, draft.splice(from, 1)[0]);
            }),
        );
    }, []);

    return (
        <>
            <StepUiHeaders ui={step.ui} />
            <Text>{t("packages.waterfall.header")}</Text>
            <Gap size="small" />
            <H5>{t("packages.waterfall.select.source")}</H5>

            <Form.Item>
                <Select
                    className={styles.waterfallInput}
                    onSelect={onDataMartSelect}
                    placeholder={t("packages.waterfall.select.placeholder")}
                >
                    {dataMarts.map(dataMart => (
                        <Option
                            title={dataMart.name}
                            key={dataMart.name}
                            value={dataMart.name}
                        >
                            {dataMart.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {dataMart && (
                <>
                    <Gap size="small" />
                    {validation.errors.length > 0 && (
                        <ErrorAlert
                            visible
                            message={t(
                                `packages.waterfall.validation.error.${validation.errors[0]}`,
                            )}
                        />
                    )}
                    <Gap size="small" />

                    <Table
                        rowHeight={50}
                        rowDragDrop={{
                            onDrop,
                        }}
                        className="waterfallTable pmTable--condensed pmTable--tallCells"
                        rowKey="id"
                        columns={createColumns(
                            handleFieldChange,
                            onDataMartFieldSelect,
                            removeAlreadySelected(fields, dataMart.fields),
                            handleRemoveRow,
                            validation.ui.columns,
                        )}
                        dataSource={validation.fields}
                        paging={false}
                        pmExpandable={{
                            expandedRowRender: record => (
                                <SubLevel
                                    parentId={record.id}
                                    subFields={record.subLevel}
                                    handleCheckBoxes={handleSubLevelChange}
                                    onDataMartFieldSelect={
                                        onDataMartFieldSubLevelSelect
                                    }
                                    onSubLevelRemoveRow={
                                        handleSubLevelRemoveRow
                                    }
                                    dataMartFields={dataMart.fields}
                                    onDrop={onSubFieldDrop}
                                    onSubLevelClear={onSubLevelClear}
                                />
                            ),
                            rowExpandable: record =>
                                record.isAllowedToCreateSubLevel,
                        }}
                    />
                    <Gap />
                    <Tooltip
                        tooltip={
                            isEmpty(dataMart.fields) &&
                            t("packages.waterfall.table.button.add-row.tooltip")
                        }
                    >
                        <>
                            <Button
                                size="small"
                                onClick={addRow}
                                label={t(
                                    "packages.waterfall.table.button.add-row",
                                )}
                            />
                        </>
                    </Tooltip>
                </>
            )}
            <ButtonGroup>
                <Button
                    type="primary"
                    disabled={validation.ui.submitButton.disabled}
                    onClick={handleOnNext}
                    label={<T id="general.continue" />}
                />
                <BackButton onBack={onBack} />
                <Button
                    type="text"
                    onClick={onCancel}
                    label={<T id="general.cancel" />}
                />
            </ButtonGroup>
        </>
    );
};

WaterfallConfiguration.propTypes = {
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};

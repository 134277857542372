import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Tag, Tooltip } from "@/components/DesignSystem";
import {
    getHealthStateTranslation,
    instanceHealthStates,
} from "@/components/InstanceHealth/utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

function getTooltipTitle(lastCheckTimestamp) {
    return (
        <>
            {t("health-state.tooltip.title")}: &nbsp;
            <FormattedDateTime>{lastCheckTimestamp}</FormattedDateTime>
        </>
    );
}

function formatWarnings(warnings, lastCheckTimestamp) {
    return (
        <>
            {getTooltipTitle(lastCheckTimestamp)}
            {warnings.map(warning => {
                return (
                    <div key={warning.warning}>
                        {t("im-instance.warning." + warning.warning)}
                    </div>
                );
            })}
        </>
    );
}

export function InstanceHealth({
    status,
    lastCheckTimestamp,
    warnings,
    small,
}) {
    if (warnings && warnings.constructor !== Array) {
        warnings = JSON.parse(warnings);
    }

    switch (status) {
        case instanceHealthStates.UP:
            return (
                <Tooltip
                    tooltip={
                        warnings?.length > 0
                            ? formatWarnings(warnings, lastCheckTimestamp)
                            : getTooltipTitle(lastCheckTimestamp)
                    }
                >
                    {warnings?.length > 0 ? (
                        <Tag size={small ? "small" : undefined} color="warning">
                            {getHealthStateTranslation(
                                instanceHealthStates.WARNING,
                            )}
                        </Tag>
                    ) : (
                        <Tag size={small ? "small" : undefined} color="success">
                            {getHealthStateTranslation(instanceHealthStates.UP)}
                        </Tag>
                    )}
                </Tooltip>
            );
        case instanceHealthStates.DOWN:
            return (
                <Tooltip tooltip={getTooltipTitle(lastCheckTimestamp)}>
                    <Tag size={small ? "small" : undefined} color="error">
                        {getHealthStateTranslation(instanceHealthStates.DOWN)}
                    </Tag>
                </Tooltip>
            );
        case instanceHealthStates.WARNING:
            return (
                <Tooltip
                    tooltip={
                        warnings?.length > 0
                            ? formatWarnings(warnings, lastCheckTimestamp)
                            : getTooltipTitle(lastCheckTimestamp)
                    }
                >
                    <Tag size={small ? "small" : undefined} color="warning">
                        {getHealthStateTranslation(
                            instanceHealthStates.WARNING,
                        )}
                    </Tag>
                </Tooltip>
            );
        default:
            return null;
    }
}

InstanceHealth.propTypes = {
    up: PropTypes.bool,
    lastCheckTimestamp: PropTypes.string,
    warnings: PropTypes.array,
    small: PropTypes.bool,
    status: PropTypes.string,
};

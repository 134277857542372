import { Forms } from "@/components/DesignSystem";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import cx from "classnames";
import { identity } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const { pmValidators } = Forms;

const isNotEqualByReference = (a, b) => a !== b;

export const getFieldByType = type => {
    switch (type) {
        case "STRING":
        case "NUMBER":
            return {
                component: Forms.Fields.Input,
                from: identity,
                to: value => `${value}`,
            };
        case "BOOLEAN":
            return {
                component: Forms.Fields.Checkbox,
                from: identity,
                to: identity,
            };
        case "JSON":
        case "JSONARRAY":
            return {
                component: Forms.Fields.Input,
                from: value => {
                    try {
                        return JSON.stringify(value);
                    } catch (e) {
                        return "";
                    }
                },
                to: value => {
                    try {
                        return JSON.parse(value);
                    } catch (e) {
                        return value;
                    }
                },
                validator: pmValidators.isValidJSON,
            };
        default:
            return {
                component: null,
                from: identity,
                to: identity,
            };
    }
};

export const FeatureFlagValue = React.memo(
    ({ name, record, enableHighlight = true }) => {
        const dirty = Forms.useFieldDirtyLoadable({ name });

        const FieldByType = getFieldByType(record.type);

        if (!FieldByType.component) {
            if (record.type)
                console.log(
                    `Missing Component for type '${
                        record.type
                    }': ${JSON.stringify(record)}`,
                );
            return null;
        }

        return (
            <StopOnKeyDownPropagation>
                <div
                    className={cx({
                        "pmFeatureFlags-value": true,
                        "pmFeatureFlags-centeredCell": true,
                        "pmFeatureFlags-highlight":
                            enableHighlight &&
                            (dirty.state === "hasValue"
                                ? dirty.contents
                                : true),
                    })}
                >
                    <FieldByType.component
                        name={name}
                        label={""}
                        size="small"
                        inputWidth="max"
                        preserveStateAfterUnmount
                        onClick={e => e.stopPropagation()}
                        dirtyComparator={isNotEqualByReference}
                        // TODO: Slow validations :(
                        // validator={FieldByType.validator}
                    />
                </div>
            </StopOnKeyDownPropagation>
        );
    },
);

FeatureFlagValue.propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    enableHighlight: PropTypes.bool,
};
